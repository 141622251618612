import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoAllianz from "../../assets/old/img/logos-ase/ico-allianz.png"

export default () => (
  <SiniestrosLayout subCompaniaActivo="allianz">
    <Helmet>
      <title>Allianz Seguros - Siniestros</title>
      <meta
        name="description"
        content="Telefónicamente al 0800-888-24324/37, desde Argentina o desde el exterior por cobro revertido al (+5411) 4329-9191/ 5238-9202."
      />
    </Helmet>

      <hr className="mb-4" />
      {/* Quotation */}
      <div className="card blog-card mb-2">
          <div className="card-body px-sm-5 py-5 bg-secondary">
              <img
                  className="float-md-right pb-2"
                  src={icoAllianz}
                  alt="Allianz Seguros"
              />
              <h4 className="lead post-title text-muted">Allianz Seguros</h4>
              <p className="">Telefónicamente al 0800-888-24324/37, desde Argentina o desde el exterior por cobro revertido al (+5411) 4329-9191/ 5238-9202.</p>
          </div>
      </div>
      <p className="pt-5 pb-3">
          Por mensaje de texto (SMS) al 50150 con el texto ALLIANZ, en pocos minutos un operador del Servicio de Emergencias se comunicará con Ud.
      </p>

      <p>
          Prestaciones brindadas en la República argentina y países limítrofes para vehículos asegurados en Allianz Argentina Compañía de Seguros S.A. (este servicio es brindado exclusivamente para autos de uso particular, jeeps y pick ups de hasta 3.500 kg. Y 2,10m. de altura).
      </p>


      <hr className="pb-4" />



  </SiniestrosLayout>
)
